#SegmentDetails label {
    display: block;
    margin: 0;
}

#SegmentDetails label input {
    margin: 10px;
}

#SegmentDetails .card-content.differentVersion {
    background-color: #f0f5f1;
}