#Packages .content {
    display: flex;    
}

#Packages .packages > div {
    margin: 10px 0;
}

#Packages textarea {
    flex-grow: 1;
}